.navbar-nav {
  @media (max-width: 990px) {
    display: flex;
    flex-direction: row;
  }
}

.nav-main {
  color: #fff;
  font-family: DINPro;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  position: relative;
  z-index: 1;

  @media (max-width: 767px) {
    background: #3E0B0C !important;
  }
}

.big-text {
  font-size: 26px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0;
  text-align: left;
}

.gold-color {
  color: #F9C95F;
}

.top-text {
  @media (max-width: 990px) {
    span {
      display: block;
    }
  }
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
}

.dropdown-item.active, .dropdown-item:active {
  text-decoration: none;
  background-color: transparent;

  .dropdown-item-text {
    color: var(--bs-dropdown-link-active-bg) !important;
  }
}
