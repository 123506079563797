//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: DINPro, sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 18.62px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .321s ease;
  will-change: transform;
  background: #000;

  &.loaded {
    opacity: 1;
  }
}

.main-box {
  min-height: 100%;
  margin-bottom: -120px;
  position: relative;

  @media (max-width: 991px) {
    margin-bottom: -98px;
  }

  @media (max-width: 767px) {
    margin-bottom: -130px;
  }
}

.footer,
.push {
  height: 120px;

  @media (max-width: 991px) {
    height: 98px;
  }

  @media (max-width: 767px) {
    height: 130px;
  }
}

.footer {
  background: #3E0B0C;
}

.style-gray {
  color: #9B9B9B;
}

.style-gold {
  color: #F9C95F;
}

.style-gold-dark {
  color: #B39A68;
}

.hr-style {
  width: 100%;
  min-width: 100%;
  height: 2px;
  background: #B39A68;
  border-radius: 1px;
}

.btn-primary {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 28px;

  &:focus,
  &:active,
  &:hover {
    color: #CCCCCC !important;
  }
}

@media (min-width: 1024px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    left: -18px;
    margin-top: 0;
    padding-top: 5px;
  }
}


//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/animations";
@import "components/slider";
@import "components/header";
@import "components/footer";
@import "components/content";
@import "components/languages";
