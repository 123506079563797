.footer {
  @media (max-width: 991px) {
    text-align: center;
  }
}

.footer-logo-box {
  width: 87px;
  height: 40px;

  svg {
    opacity: .47;
    padding: 0 4px;
  }

  @media (max-width: 991px) {
    width: 56px;
    height: 28px;
  }
}

.footer-container {
  @media (max-width: 991px) {
    display: flex !important;
    padding-bottom: 30px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 22px;
  }

  @media (max-width: 767px) {
    display: inline-block !important;
    vertical-align: top;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .footer-container {
    padding-top: 5px;
  }
}

.logo-box {
  @media (max-width: 767px) {
    width: 15%;
    display: inline-block;
    margin-right: -2px !important;
    vertical-align: top;
  }

  @media (min-width: 992px) and (max-width: 1100px) {
    .footer-logo-box {
      width: 77px;
      height: 33px;
    }
  }
}