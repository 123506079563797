.glide {
  width: 100%;
  height: 100%;
  margin-bottom: 47px;
  padding: 0;

  *:not(.style-gray) {
    color: #fff;
  }

  .jackpot-box {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    padding: 10px 20px 0;
    width: 100%;
    max-width: 256px;
    min-width: 256px;
  }

  .chest-box {
    top: 25px;
    right: 11px;
  }
}

.mobile-slider {
  padding: 0;

  .gold-box {
    min-width: 256px;
  }

  .jackpot-title {
    font-size: 12px;
  }

  .money-box {
    font-size: 14px;
  }
}

.glide__slides {
  overflow-x: auto;
  touch-action: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  padding-right: 12px;
}

.glide__slides::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.glide__slide {
  padding-left: 12px;
  padding-right: 12px;
  min-width: 256px;
  width: 256px;
  margin-right: 15px;
}
