$mobileView: 991px;

.main-image-box {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
  position: absolute;
  z-index: -1;
}

.main-image {
  background-image: url(../../images/main/main.jpg?v1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  will-change: transform;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  @media (max-width: 1990px) {
    background-image: url(../../images/main/MB_GIRLS_1990px.jpg?v11);
  }

  @media (max-width: 1400px) {
    background-image: url(../../images/main/MB_GIRLS_1440px.jpg?v11);
  }

  @media (max-width: 999px) {
    background-image: url(../../images/main/MB_GIRLS_1990px.jpg?v11);
  }

  @media (min-width: 1500px) {
    left: -80px;
    height: 107%;
    width: 112%;
  }

  //@media (min-width: 1280px) and (max-width: 1500px) {
  //  left: -40px;
  //}

  @media (max-width: 1279px) {
    transform: translateX(0) translateY(0) !important;
  }
}

.right-sidebar {
  margin-top: 92px;
  margin-bottom: 91px;
  padding-left: 0 !important;
  padding-right: 0 !important;

  @media (min-width: 992px) {
    max-width: 272px;
  }
  @media (max-width: $mobileView) {
    margin: 42px auto;
    //display: none !important;
  }

  h1,
  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
  }

  .chest-box {
    right: -5px;

    @media (min-width: 768px) and (max-width: 991px) {
      right: -2px;
    }
  }

  .right-sidebar-inner {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 10px;

    @media (min-width: 768px) and (max-width: 991px) {
      height: 100%;
    }

    @media (max-width: 767px) {
      width: 100vw;
      transform: translateX(-50vw);
      left: 50%;
      position: relative;
      list-style: none;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
      will-change: transform;
      background: none;
      margin: 0 15px 0 0;
      overflow-x: auto;
      touch-action: auto;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none;
      padding: 0 12px 0 0;
      border-radius: 0;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      h1 {
        display: none;
      }

      .gold-box {
        min-width: 256px;
      }

      .jackpot-title {
        font-size: 12px;
      }

      .money-box {
        font-size: 14px;
      }

      .jackpot-box {
        background: rgba(0, 0, 0, 0.7);
        border-radius: 10px;
        padding: 10px 20px 0;
        width: 100%;
        max-width: 256px;
        min-width: 256px;
        margin-left: 15px;
      }

      .chest-box {
        top: 25px;
        right: 11px;
      }

      .mobile-slider .money-box {
        font-size: 14px;
      }

      .progress-box {
        top: 39px;
        left: 28px;
      }

      .money-box {
        left: 29px;
      }

    }
  }
}

.right-content-box,
.left-content-box {
  @media (max-width: $mobileView) {
    margin: auto;
  }
}

.right-content-box {
  @media (max-width: $mobileView) {
    max-width: none;
    //display: block !important;
  }

  .progress-box {
    @media (max-width: $mobileView) {
      top: 39px;
      left: 28px;
    }
  }

  .money-box {
    @media (max-width: $mobileView) {
      left: 29px;
    }
  }
}

.left-sidebar {
  margin-top: 92px;
  margin-bottom: 91px;
  max-width: 550px;
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid #B39A68;
  border-radius: 10px;
  padding: 30px;

  @media (min-width: 992px) {
    max-width: 550px;
  }

  @media (max-width: $mobileView) {
    margin: 42px auto;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }

  h1,
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 43px;

    @media (max-width: $mobileView) {
      font-size: 20px;
      line-height: 38px;
    }

    svg {
      margin-right: 8px;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;

    @media (max-width: $mobileView) {
      font-size: 18px;
    }
  }
}

.progress-box {
  position: absolute;
  top: 29px;
  left: 11px;
}

.jackpot-min,
.money-box,
.chest-box,
.jackpot-box {
  opacity: 0;
  visibility: hidden;
  transition: opacity .0001s ease;
  will-change: transform;

  &.visible .jackpot-title,
  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.jackpot-box {
  width: 218px;

  @media (max-width: $mobileView) {
    font-size: 18px;
  }

  @media (min-width: 768px) and (max-width: $mobileView) {
    float: left;
    width: 206px;
    font-size: 13px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    > svg {
      width: 200px;
    }

    .progress-box svg {
      width: 12px;
    }
  }
}

.jackpot-title,
.chest-box {
  transition: opacity .654s ease;
  will-change: transform;
}

.jackpot-min {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.chest-box {
  position: absolute;
  top: 15px;
  right: 1px;
}

.text-box {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.money-box {
  position: absolute;
  bottom: 50px;
  left: 12px;

  span {
    letter-spacing: 11px;
  }
}

.navbar-expand-lg .navbar-collapse {
  flex-basis: auto;
  display: flex;
}


// Loading
.not-loaded {

  //&:not(.loaded) {
  //  .navbar-brand {
  //    position: fixed;
  //    top: 50%;
  //    left: 50%;
  //    margin-left: -60px;
  //    margin-top: -18px;
  //  }
  //
  //  .nav-main {
  //    background: none !important;
  //  }
  //
  //  .navbar-collapse {
  //    visibility: hidden;
  //  }
  //}

  &.loaded {
    .loading-box {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 1.1s, opacity 1.1s linear;
    }

    .navbar-brand {
      animation: fadeInUp2;
      opacity: 1;
    }
  }

  .loading-box {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1;
    visibility: visible;
    opacity: 1;
    transition: opacity .6s linear;
    will-change: transform;
  }
}
