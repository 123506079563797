//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";
@import "mixins/load-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------

@font-face {
  font-family: DINPro;
  src: url("../fonts/DINPro/DINPro-Black.eot");
  src: local("DINPro Black"), local("DINPro-Black"), url("../fonts/DINPro/DINPro-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro/DINPro-Black.woff") format("woff"), url("../fonts/DINPro/DINPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DINPro;
  src: url("../fonts/DINPro/DINPro.eot");
  src: local("DINPro Regular"), local("DINPro-Regular"), url("../fonts/DINPro/DINPro.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro/DINPro.woff") format("woff"), url("../fonts/DINPro/DINPro.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DINPro;
  src: url("../fonts/DINPro/DINPro-Medium.eot");
  src: local("DINPro Medium"), local("DINPro-Medium"), url("../fonts/DINPro/DINPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro/DINPro-Medium.woff") format("woff"), url("../fonts/DINPro/DINPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DINPro;
  src: url("../fonts/DINPro/DINPro-Bold.eot");
  src: local("DINPro Bold"), local("DINPro-Bold"), url("../fonts/DINPro/DINPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro/DINPro-Bold.woff") format("woff"), url("../fonts/DINPro/DINPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: DINPro;
  src: url("../fonts/DINPro/DINPro-Light.eot");
  src: local("DINPro Light"), local("DINPro-Light"), url("../fonts/DINPro/DINPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro/DINPro-Light.woff") format("woff"), url("../fonts/DINPro/DINPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
