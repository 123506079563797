.fadeIn {
  animation: fadein .7s;
}

.fadeInUp2 {
  animation-duration: .6s;
  animation-fill-mode: both;
  opacity: 0;
  animation-name: fadeInUp2;
}

.fadeInUp3 {
  animation-duration: .4s;
  animation-fill-mode: both;
  opacity: 0;
  animation-name: fadeInUp2;
}

.fadeInUp {
  animation-duration: 1s;
  animation-fill-mode: both;
  opacity: 0;
  animation-name: fadeInUp;
}


.t-delay-1s {
  transition-delay: 1s !important;
}

.t-delay-500ms {
  transition-delay: 500ms !important;
}

.t-delay-200ms {
  transition-delay: 200ms !important;
}

.t-delay-100ms {
  transition-delay: 100ms !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 10px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@keyframes fadeInUp2 {
  from {
    transform: translate3d(0, 4px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}